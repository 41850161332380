<!--
 * @Author: your name
 * @Date: 2021-10-29 14:38:05
 * @LastEditTime: 2022-04-13 11:11:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-exam\src\views\home\index.vue
-->
<template>
  <div class="home">
    <FaceRecognition
      v-if="showFace"
      :showFace="showFace"
      :goExam="goExam"
      :detail="examDetail"
      @closepop="closeFace"
    ></FaceRecognition>
    <div class="home-header">
      <div class="banner">
        <el-carousel trigger="click" height="642px">
          <Nav></Nav>

          <el-carousel-item v-for="item in 4" :key="item">
            <img
              style="object-fit: fill; width: 100%"
              src="../../assets/home/banner.png"
              alt=""
            />
            <!-- <img style="height: 642px; width: 100%" src="../../assets/home/banner.jpg" alt="" /> -->
          </el-carousel-item>
        </el-carousel>
        <div class="user-detail">
          <div class="detail-top">
            <div class="userInfo">
              <div v-if="userInfo.extraProperties">
                <img class="userInfo-img" v-if="imgUrl" :src="imgUrl" alt="" />
                <img
                  v-else-if="userInfo.extraProperties.Sex == 'female'"
                  class="userInfo-img"
                  src="../../assets/female.png"
                  alt=""
                />
                <img
                  v-else
                  class="userInfo-img"
                  src="../../assets/male.png"
                  alt=""
                />
              </div>
              <div v-if="userInfo.extraProperties">
                <div style="margin-bottom: 16px">
                  <span class="code" style="color: #666666">登录名称：</span
                  ><span style="margin-left: 10px" class="code"
                    >{{ userInfo.userName }}
                  </span>
                </div>
                <div>
                  <span class="name" style="color: #666666">用户姓名：</span
                  ><span style="margin-left: 10px" class="name">{{
                    userInfo.name
                  }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="detail-text" style="color: #44d7b6"><img src="../../assets/home/data.png" alt="" /> <span>数据面板</span></div> -->
            <div
              class="detail-text"
              v-if="auth"
              @click="goBackstage"
              style="color: #1fa3ff; cursor: pointer"
            >
              <img src="../../assets/home/setting.png" alt="" />
              <span>后台管理</span>
            </div>
          </div>
          <div class="detail-bottom">
            <div class="bottom-item" @click="goExamList">
              <img
                class="exam-icon"
                src="../../assets/home/exam-icon.png"
                alt=""
              />
              <div class="message">
                <div class="number">
                  {{ waitExamCount }}<span class="unit">场</span>
                </div>
                <div class="text">
                  <span style="margin-right: 10px">考试待参加</span>
                  <img src="../../assets/home/triangle.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-main">
      <div class="message-modal">
        <div class="title">
          <img src="../../assets/home/exam-centre.png" alt="" />
          <span>考试中心</span>
        </div>
        <img src="../../assets/home/exam-desc.png" alt="" />
        <div class="description" :class="[examList.length < 2 && 'only']">
          <template v-if="examList.length != 0">
            <div
              class="desc-item"
              :class="[index == examList.length - 1 && 'end']"
              @mouseover="mouseEnter(item)"
              @click="openFaceModal(item)"
              @mouseout="mouseOut(item)"
              v-for="(item, index) in examList"
              :key="index"
            >
              <div class="exam-time">
                {{ item.examTime ? item.examTime / 60 : "不限时" }}
                <span v-if="item.examTime" class="unit">分钟</span>
              </div>
              <div class="exam-name">{{ item.examName }}</div>
              <div class="exam-date">
                {{ item.beginTimeFormat }} ~ {{ item.endTimeFormat }}
              </div>
              <div class="exam-join">
                <div class="exam-num">
                  <span class="num-left">参加次数：</span>
                  <span class="num-right"
                    >{{ item.attendTimes }}/{{
                      item.maxExamNum ? item.maxExamNum : "无限"
                    }}</span
                  >
                </div>
                <img
                  src="../../assets/home/join.png"
                  style="width: 36px; cursor: pointer"
                  v-if="!item.isHover"
                  @mouseover="mouseEnter(item)"
                  class="exam-img"
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/home/join-select.png"
                  style="width: 36px; cursor: pointer"
                  @mouseout="mouseOut(item)"
                  class="exam-img"
                  alt=""
                />
              </div>
            </div>
          </template>
          <div v-else class="empty">
            <img
              style="margin-right: 40px"
              src="../../assets/home/exam-center-empty.png"
              alt=""
            />
            <span>精选试题正在路上 …</span>
          </div>
        </div>
        <div class="file-btn" style="margin: 22px auto 0" @click="goExamList">
          <span style="margin-right: 8px"> 查看全部 </span>
          <img src="../../assets/list/arrows-right.png" alt="" />
        </div>
      </div>
      <div class="message-modal" style="margin: 120px auto">
        <div class="title">
          <img src="../../assets/home/message.png" alt="" />
          <span>资讯中心</span>
        </div>
        <div>
          <el-carousel trigger="click" height="536px" v-if="newsList.length">
            <el-carousel-item v-for="(item, index) in newsList" :key="index">
              <div class="message-carousel-item">
                <div class="content">
                  <div class="content-title">{{ item.newsSubject }}</div>
                  <div class="content-text" v-html="item.newsContent"></div>
                  <div class="content-date">{{ item.publishTime }}</div>
                  <div @click="goNewsDetail(item)" class="news-btn">
                    <span style="margin-right: 8px"> 了解详情 </span>
                    <img
                      src="../../assets/home/arrows-right-white.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="content-img">
                  <img v-if="item.bannerImg" :src="item.bannerImg" alt="" />
                  <img v-else src="../../assets/normal-banner.png" alt="" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <img
            v-else
            style="margin-top: 70px; width: 450px"
            src="../../assets/home/newslist-empty.png"
            alt=""
          />
        </div>
      </div>
      <div class="foother-modal">
        <div class="foother-left">
          <img class="foother-logo" src="../../assets/logo.png" alt="" />
          <div class="foother-title">SmartExam Universe</div>
          <div class="foother-content">E-Learning 解决方案领导厂商</div>
        </div>
        <div class="foother-right">
          <div class="foother-nav">
            <div
              class="nav-item"
              style="cursor: pointer"
              @click="goLink(`/exam/list`)"
            >
              考试中心
            </div>
            <div class="nav-item">
              新闻中心
              <div class="sub-item" @click="goLink(`/newsList/list`)">
                新闻公告
              </div>
            </div>
            <!-- <div class="nav-item">帮助中心</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="showMaskModal" @click="showMaskModal = false">
      <div class="tips-modal" @click.stop>
        <div class="modal-head">
          <img
            class="icon"
            src="../../assets/components/error-icon.png"
            alt=""
          />
          温馨提示
        </div>
        <div class="modal-main">
          <div class="main-head">
            系统未查询到您的相关信息 请选择以下任一方式进行身份认证
          </div>
          <div class="main-body">
            <photograph
              v-if="showPhotograph"
              :showFace="showPhotograph"
              @closepop="closeModal"
              @closeAll="closeAll"
            ></photograph>
            <el-upload
              v-else-if="imageUrl && !showPhotograph"
              class="avatar-uploader"
              action=""
              v-model="avatar"
              :show-file-list="false"
              :on-change="handleChange"
              :auto-upload="false"
            >
              <img :src="imageUrl" class="avatar" />
            </el-upload>
            <img
              v-else-if="userInfo.extraProperties.Sex == 'female'"
              class="userInfo-img"
              src="../../assets/components/head-img-female.png"
              alt=""
            />          
            <img v-else src="../../assets/components/head-img.png" alt="" />
          </div>
          <div class="main-foot" v-if="!showPhotograph">
            <div v-if="imageUrl" @click.stop="upload" class="btn">
              <img
                style="margin-right: 15px"
                src="../../assets/components/upload-icon.png"
                alt=""
              />
              <div>确认上传</div>
            </div>
            <el-upload
              v-else
              class="avatar-uploader"
              action=""
              v-model="avatar"
              :show-file-list="false"
              :on-change="handleChange"
              :auto-upload="false"
            >
              <!-- :before-upload="beforeAvatarUpload" -->
              <div class="btn">
                <img
                  style="margin-right: 15px"
                  src="../../assets/components/upload-icon.png"
                  alt=""
                />
                <div>选择照片</div>
              </div>
            </el-upload>
            <div
              v-if="!imageUrl"
              class="btn"
              style="margin-left: 50px"
              @click="showPhotographModal"
            >
              <img
                style="margin-right: 15px"
                src="../../assets/components/upload-icon.png"
                alt=""
              />
              <div>拍照上传</div>
            </div>
            <div
              v-else
              class="btn"
              style="margin-left: 50px; width: 80px"
              @click="reset"
            >
              返回
            </div>
            <!-- <el-button
              type="primary"              
              @click.stop="upload"
              >确认</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import FaceRecognition from "@/components/faceRecognition";
import Api from "@/api/examList.js";
import homeApi from "@/api/home";
import userApi from "@/api/home";
import Cookie from "js-cookie";
import axios from "axios";
import photograph from "@/components/photograph";

export default {
  components: {
    Nav,
    FaceRecognition,
    photograph,
  },
  data() {
    return {
      avatar: "",
      formData: {},
      showMaskModal: false,
      isHover: false,
      waitExamCount: 0,
      listQuery: {
        SkipCount: 0,
        MaxResultCount: 3,
        examUserListSearchBase: {
          examClassCode: "exam",
        },
      },
      examList: [],
      newsList: [],
      userInfo: {
        extraProperties: {
          UserCode: "",
        },
      },
      Link: "",
      imgUrl: "",
      auth: JSON.parse(sessionStorage.getItem("auth")),
      examDetail: {},
      showPhotograph: false,
      showFace: false,
      imageUrl: "",
    };
  },
  methods: {
    reset() {
      this.imageUrl = "";
    },
    showPhotographModal() {
      this.showPhotograph = true;
    },
    handleChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      console.warn("isLt2M", isLt2M);
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      } else {
        console.warn(file.raw);
        let formData = new FormData();
        this.formData = formData;
        formData.append("file", file.raw);
        console.warn(this.formData);
        this.imageUrl = URL.createObjectURL(file.raw);
        console.warn("imageUrl", this.imageUrl);
        this.$forceUpdate();
      }
      return isLt2M;
    },
    closeAll() {
      this.showMaskModal = false;
      this.showPhotograph = false;
    },
    closeModal() {
      this.showFace = false;
      this.showPhotograph = false;
    },
    upload() {
      let fileRelationId = "";
      if (sessionStorage.getItem("userId")) {
        fileRelationId = sessionStorage.getItem("userId");
      }
      axios
        .post(
          `/api/app/file/upload-file-system?fileTypeCode=user&fileRelationId=${fileRelationId}`,
          this.formData
        )
        .then((res) => {
          this.avatar = res.data;
          axios
            .post(
              `/api/base/user/faceimage/${fileRelationId}?blobName=${this.avatar.blobName}`
            )
            .then((res) => {
              if (res) {
                this.$message.success("照片上传成功");
                this.userInfo.extraProperties.FaceImage = this.avatar.blobName;
                sessionStorage.setItem(
                  "userInfo",
                  JSON.stringify(this.userInfo)
                );
                this.closeAll();
              } else {
                this.$message.success("照片上传失败，请重新上传或联系管理员");
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleScroll() {
      let scrollY = window.scrollY;
      let scrollObj = document.querySelector(".list-nav");
      if (scrollY > 400) {
        //这个358就是上面的距离
        scrollObj.style.position = "fixed";
        scrollObj.style.top = "0";
        scrollObj.style.background = "rgba(255, 255, 255)";
      } else {
        scrollObj.style.position = "absolute";
        scrollObj.style.top = "0";
        scrollObj.style.background = "rgba(255, 255, 255, 0.3)";
      }
    },
    closeFace() {
      this.showFace = false;
    },
    openMaskModal() {
      this.showMaskModal = true;
    },
    openFaceModal(item) {
      if (!item.isOpenFaceAuthentication) {
        this.examDetail = item;
        this.showFace = true;
      } else {
        if (sessionStorage.getItem("userInfo")) {
          this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        }
        if (this.userInfo.extraProperties.FaceImage) {
          console.warn("有头像");
          this.examDetail = item;
          this.showFace = true;
          this.$forceUpdate();
        } else {
          this.openMaskModal();
        }
      }
    },
    goBackstage() {
      let token = sessionStorage.getItem("token");
      let url = this.Link.split("#")[0];
      homeApi
        .getNewToken(url, token)
        .then((res) => {
          Cookie.set("Admin-Token", res.data);
          window.location.href = this.Link;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    goLink(item) {
      this.$router.push(item);
    },
    goNewsDetail(item) {
      this.$router.push(`/newsList/detail?id=${item.id}`);
    },
    goExamList() {
      this.$router.push("/exam/list");
    },
    jscomNewOpenMaxWindow(url, target) {
      let tt, w, left, top, width, height;
      width = screen.width;
      height = screen.height - 60;
      left = 0;
      if (left < 0) {
        left = 0;
      }

      top = 0;
      if (top < 0) {
        top = 0;
      }

      tt =
        "toolbar=no, menubar=no, scrollbars=yes,resizable=no,location=no, status=yes,fullscreen = yes,";
      tt =
        tt +
        "width=" +
        width +
        ",height=" +
        height +
        ",left=" +
        left +
        ",top=" +
        top;
      w = window.open(url, target, tt);
      try {
        w.focus();
        return w;
      } catch (e) {}
    },
    goExam(item) {
      console.warn("打包成功验证");
      let params = {};
      if (item.maxExamNum) {
        if (item.attendTimes >= item.maxExamNum) {
          this.$message.error("参加次数已超过限制,请联系管理员");
          return;
        }
      }
      console.warn(item);
      if (item.isOpenFaceAuthentication) {
        params.faceVerificationKey = item.verificationKey;
        params.examGradeUid = item.examGradeUid;
      }
      if (sessionStorage.getItem("baseDetail")) {
        sessionStorage.removeItem("baseDetail");
      }
      if (item.examDoModeCode == "paper") {
        Api.checkExam(item.examUid, item.examArrangeUid, params)
          .then((res) => {
            let routeData;
            if (item.isOpenFaceAuthentication) {
              routeData = this.$router.resolve({
                path: "/exam/paper",
                query: {
                  examUid: item.examUid,
                  examArrangeUid: item.examArrangeUid,
                  faceVerificationKey: item.verificationKey,
                  examGradeUid: item.examGradeUid,
                },
              });
            } else {
              routeData = this.$router.resolve({
                path: "/exam/paper",
                query: {
                  examUid: item.examUid,
                  examArrangeUid: item.examArrangeUid,
                },
              });
            }
            this.jscomNewOpenMaxWindow(routeData.href, "_blank");
            this.requestExamDetail();
          })
          .catch((err) => {
            console.warn(err);
          });
      } else if (item.examDoModeCode == "question") {
        Api.checkExam(item.examUid, item.examArrangeUid, params)
          .then((res) => {
            let routeData;
            if (item.isOpenFaceAuthentication) {
              routeData = this.$router.resolve({
                path: "/exam/question",
                query: {
                  examUid: item.examUid,
                  examArrangeUid: item.examArrangeUid,
                  faceVerificationKey: item.verificationKey,
                  examGradeUid: item.examGradeUid,
                },
              });
            } else {
              routeData = this.$router.resolve({
                path: "/exam/question",
                query: {
                  examUid: item.examUid,
                  examArrangeUid: item.examArrangeUid,
                },
              });
            }
            this.jscomNewOpenMaxWindow(routeData.href, "_blank");
            this.requestExamDetail();
          })
          .catch((err) => {
            console.warn(err);
          });
      }
      this.closeFace();
    },
    mouseEnter(item) {
      item.isHover = true;
      this.$forceUpdate();
    },
    mouseOut(item) {
      item.isHover = false;
      this.$forceUpdate();
    },
    requestExamDetail() {
      Api.getExamUserInfo()
        .then((res) => {
          this.waitExamCount = res.data.waitExamCount;
        })
        .catch((err) => {
          console.warn(err);
        });
      Api.getList(this.listQuery)
        .then((res) => {
          this.examList = res.data.items;
          this.examList.forEach((item) => {
            item.isHover = false;
          });
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    requestNewsDetail() {
      let params = {
        SkipCount: 0,
        MaxResultCount: 3,
      };
      Api.getNewsList(params)
        .then((res) => {
          this.newsList = res.data.items;
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    requestDetail() {
      this.requestExamDetail();
      this.requestNewsDetail();
      this.getUserDetail();
    },
    getAvatar(blobName) {
      if (blobName) {
        let url = this.Link.split("#")[0];
        sessionStorage.setItem("Link", url);
        axios
          .get(`${url}api/app/file/download-file-system/${blobName}`, {
            responseType: "blob",
          })
          .then(({ data }) => {
            let blob = new Blob([data]); // 返回的文件流数据
            let url = window.URL.createObjectURL(blob); // 将他转化为路径
            this.imgUrl = url; // 将转换出来的路径赋值给变量，其实和上一步结合就可以
          });
        this.$forceUpdate();
      }
    },
    getUserDetail() {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (
        this.userInfo &&
        this.userInfo.extraProperties &&
        this.userInfo.extraProperties.Avatar
      ) {
        this.getAvatar(this.userInfo.extraProperties.Avatar);
      }
      userApi
        .getCopyright()
        .then((res) => {
          res.data.forEach((item) => {
            if (item.name == "Abp.Identity.User.AdminUrl") {
              this.Link = item.value;
            }
          });
        })
        .catch((err) => {
          console.warn(err);
        });
    },
  },
  created() {
    this.requestDetail();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__button {
  height: 5px;
  border-radius: 5px;
}
.home {
  // height: 100%;
  // background: url('../../assets/home/home-bg.jpg');
  background-size: 100%;
  background-repeat: repeat-y;
  .mask {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    // display: none;
    z-index: 20;
    background: rgba(0, 0, 0, 0.5);
    // filter: alpha(opacity=60);
    // opacity: 0.5 !important;
    .tips-modal {
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -285px;
      margin-left: -240px;
      width: 480px;
      height: 570px;
      background: #ffffff;
      border-radius: 18px;
      z-index: 50 !important;
      background-color: White;

      .modal-head {
        box-sizing: border-box;
        height: 90px;
        text-align: center;
        padding: 30px 0;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          margin-right: 10px;
        }
      }
      .modal-main {
        .main-head {
          width: 240px;
          height: 53px;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          color: #999999;
          line-height: 32px;
          margin: 20px auto 30px;
        }
        .main-body {
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          .avatar {
            width: 258px;
            height: 258px;
          }
        }
        .main-foot {
          width: 100%;
          display: flex;
          justify-content: center;
          .btn {
            width: 145px;
            height: 57px;
            background: #f8f8f8;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .home-header {
    position: relative;

    .banner {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        box-shadow: 0 0 50px 30px #ffffff inset;
      }
      // height: 642px;
      // padding-top: 86px;

      .user-detail {
        z-index: 9;
        bottom: -102px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 1211px;
        height: 204px;
        background: #fff;
        // border: 1px solid #fff;
        border-radius: 35px;
        padding: 26px 90px;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
        // box-shadow: 18px 0px 36px 18px rgba(0, 0, 0, 0.07);

        // padding-top: 26px;
        .detail-top {
          display: flex;
          align-items: center;
          .detail-text {
            margin-left: 70px;
            font-size: 16px;
            // font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            line-height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 148px;
            height: 57px;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 29px;
            box-shadow: 0px 40px 79px 0px rgba(0, 0, 0, 0.07);
            backdrop-filter: blur(100px);
          }
          .userInfo {
            display: flex;
            align-items: center;
            font-size: 16px;
            // font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
            line-height: 22px;
            .userInfo-img {
              width: 64px;
              height: 64px;
              margin-right: 18px;
              border-radius: 50%;
              overflow: hidden;
            }
            .code {
              height: 22px;
              font-size: 16px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: center;
              color: #333333;
            }
            .name {
              height: 22px;
              font-size: 16px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: center;
              // font-family: PingFangSC, PingFangSC-Medium;
              color: #333333;
            }
          }
        }
        .detail-bottom {
          margin-top: 24px;
          .bottom-item {
            display: flex;
            align-items: center;
            .exam-icon {
              width: 40px;
              height: 45px;
              margin-right: 26px;
            }
            .message {
              text-align: left;
              .number {
                font-size: 32px;
                // font-family: DINAlternate, DINAlternate-Bold;
                font-weight: 700;
                color: #1e2046;
                .unit {
                  font-size: 14px;
                  // font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400;
                  color: #666666;
                }
              }
              .text {
                font-size: 16px;
                // font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #1e2046;
              }
            }
          }
        }
      }
    }
  }
  .home-main {
    padding-top: 150px;
    background: url("../../assets/home/home-bg.jpg");
    .message-modal {
      width: 1300px;
      margin: 60px auto;
      // display: flex;
      text-align: center;

      .title {
        font-size: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        // font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #1e2046;
        // line-height: 53px;
        img {
          margin-top: 6px;
          margin-right: 16px;
          width: 39px;
          height: 33px;
        }
      }
      .description {
        width: 1236px;
        height: 311px;
        background: url("../../assets/home/exam-group.png");
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 96px;
        & .nothree {
          justify-self: flex-start;
        }
        .empty {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1044px;
          height: 192px;
          background: #ffffff;
          border: 2px solid;
          border-image: linear-gradient(148deg, #ffffff 1%, #ffffff 90%) 2 2;
          border-radius: 18px;
          box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.04);
          // flex-direction: column;
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          color: #666666;
          line-height: 54px;
        }
        .desc-item {
          cursor: pointer;
          padding: 18px 16px 8px 36px;
          width: 318px;
          height: 192px;
          margin-right: 45px;
          background: #ffffff;
          border: 2px solid;
          border-image: linear-gradient(148deg, #ffffff 1%, #ffffff 90%) 2 2;
          border-radius: 18px;
          box-shadow: 0px 32px 64px 0px undefined;
          .exam-time {
            font-size: 42px;
            // font-family: DIN, DIN-Bold;
            font-weight: 700;
            text-align: left;
            color: #1fa3ff;
            line-height: 52px;
            .unit {
              font-size: 16px;
              // font-family: PingFangSC, PingFangSC-Light;
              font-weight: 300;
              text-align: left;
              color: #000000;
              line-height: 22px;
            }
          }
          .exam-name {
            font-size: 16px;
            // font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 700;
            text-align: left;
            color: #000000;
            line-height: 22px;
            margin: 12px 0;
          }
          .exam-date {
            font-size: 12px;
            // font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 17px;
          }
          .exam-join {
            display: flex;
            justify-content: space-between;
            height: 36px;
            align-items: center;
            margin-top: 8px;
            .exam-num {
              font-size: 12px;
              // font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              line-height: 17px;
              .num-left {
                color: #000000;
                font-weight: 700;
              }
              .num-right {
                color: #666666;
              }
            }
          }
          &.end {
            margin-right: 0 !important;
          }
        }
      }
      & .only {
        justify-content: flex-start !important;
        // padding-left: 60px;
        .desc-item {
          margin-right: 45px;
        }
      }
      .file-btn {
        width: 126px;
        cursor: pointer;
        height: 42px;
        // opacity: 0.47;
        border: 1px solid #cacaca;
        font-size: 16px;
        // font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .news-btn {
        width: 126px;
        cursor: pointer;
        height: 42px;
        // opacity: 0.47;
        border: 1px solid #cacaca;
        background: linear-gradient(155deg, #48b4ff 0%, #1fa3ff 98%);
        font-size: 16px;
        // font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .message-carousel-item {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        .content {
          width: 576px;
          height: 395px;
          position: relative;
          text-align: left;
          .content-title {
            font-size: 32px;
            // font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: left;
            color: #000000;
            line-height: 45px;
          }
          .content-text {
            margin-top: 52px;
            font-size: 16px;
            // font-family: PingFangSC, PingFangSC-Light;
            font-weight: 300;
            text-align: left;
            color: #333333;
            line-height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
          .content-date {
            margin-top: 18px;
            margin-bottom: 72px;
            font-size: 12px;
            // font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 17px;
          }
        }
        .content-img {
          img {
            width: 608px;
            height: 395px;
            border-radius: 50px;
            object-fit: contain;
          }
        }
      }
    }
    .foother-modal {
      // height: 400px;
      padding-bottom: 100px;
      border-bottom: 1px solid #e9ecf4;
      //  #3e4294
      width: 1300px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .foother-left {
        .foother-logo {
          width: 160px;
          height: 30px;
        }
        .foother-title {
          width: 474px;
          height: 26px;
          font-size: 38px;
          // font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 700;
          text-align: left;
          color: #1e2046;
          line-height: 26px;
          margin-top: 42px;
        }
        .foother-content {
          width: 360px;
          height: 42px;
          font-size: 18px;
          // font-family: PingFangSC, PingFangSC-Light;
          font-weight: 300;
          text-align: left;
          color: #1e2046;
          line-height: 42px;
          margin-top: 24px;
        }
      }
      .foother-right {
        .foother-nav {
          display: flex;
          .nav-item {
            margin-right: 45px;
            width: 72px;
            min-height: 25px;
            font-size: 18px;
            // font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 700;
            text-align: left;
            color: #0e1735;
            line-height: 25px;
            .sub-item {
              cursor: pointer;
              width: 56px;
              height: 20px;
              font-size: 14px;
              // font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #a5a5a5;
              margin-top: 12px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-carousel__indicator.is-active button {
  opacity: 1;
  background-color: #1fa3ff !important;
}
::v-deep .el-carousel__button {
  background-color: #e2e4e8;
}
.el-carousel__item:nth-child(2n) {
  // background-color: #99a9bf;
  //    background-color:transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: #d3dce6;
}
</style>